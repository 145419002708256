export default class Entity {
  constructor(
    id,
    available,
    candidates,
    client,
    description,
    descriptionIntl,
    finalDate,
    formation,
    languages,
    documents,
    locale,
    category,
    place,
    privat,
    professionalExperience,
    publicationDate,
    status,
    title,
    jobTitle,
    type,
    views,
    essentialSkills
  ) {
    this.id = id;
    this.available = available;
    this.candidates = candidates;
    this.client = client;
    this.description = description;
    this.descriptionIntl = descriptionIntl;
    this.finalDate = finalDate;
    this.formation = formation;
    this.languages = languages;
    this.documents = documents;
    this.locale = locale;
    this.category = category;
    this.place = place;
    this.private = privat;
    this.professionalExperience = professionalExperience;
    this.publicationDate = publicationDate;
    this.status = status;
    this.title = title;
    this.jobTitle = jobTitle;
    this.type = type; // Emprego || Estágio
    this.views = views;
    this.essentialSkills = essentialSkills;
  }
}
