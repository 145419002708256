<template>
  <v-container class="fill-heigt pt-0" fluid>
    <v-row class="justify-center">
      <!-- {{ setStages }} -->
      <v-col cols="12" md="10">
        <v-stepper v-model="e1" color="secondary">
          <v-stepper-header>
            <v-stepper-step editable step="1">
              {{ language.postingForm }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2">{{ language.toView }} </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-alert
                v-model="alert"
                color="primary_text"
                dense
                type="info"
                class="text-center black--text caption mb-3"
                dismissible
                >{{ $t("worning_match") }}</v-alert
              >

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="pt-1">
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="vacancy.client"
                      :items="clients"
                      item-text="name"
                      item-value="id"
                      :label="language.clienteName"
                      dense
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="vacancy.type"
                      :items="language.tvacancy"
                      item-text="name"
                      item-value="_id"
                      :label="language.typeVacancy"
                      dense
                      outlined
                      hide-details
                      :rules="requiredRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="vacancy.category"
                      :items="language.categoryOptions"
                      item-text="name"
                      item-value="_id"
                      :label="language.availability"
                      dense
                      outlined
                      hide-details
                      :rules="requiredRules"
                    ></v-select>
                  </v-col>
                  <!-- <v-col v-if="this.$route.query.id && (!vacancy.jobTitle.pt || !vacancy.jobTitle.en)" cols="12" md="12">
                    <v-text-field
                      v-model="vacancy.title"
                      :label="`${language.jobTitle} - PT`"
                      dense
                      outlined
                      hide-details
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col> -->
                  <template>
                    <v-col v-for="(lang, index) in vacancy.languages" cols="12" md="12" :key="`${index}lang`">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="lang.name"
                            :items="language.languageOptions"
                            item-text="label"
                            item-value="val"
                            :label="language.language"
                            dense
                            outlined
                            hide-details
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="5">
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-autocomplete
                                v-model="lang.level"
                                :items="language.levelLanguageOptions1"
                                item-text="text"
                                item-value="key"
                                :label="language.level"
                                dense
                                outlined
                                hide-details
                                required
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="lang.weight"
                                :label="language.weight"
                                min="1"
                                dense
                                outlined
                                :rules="requiredRulesNumb"
                                hide-details
                                required
                                @input="validateIntNumber(lang.weight)"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="1" class="text-center mt-1">
                          <v-btn
                            x-small
                            outlined
                            fab
                            color="secondary"
                            @click="
                              index === 0
                                ? addLanguage()
                                : removeLanguage(index)
                            "
                          >
                            <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                            <v-icon v-else small>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                  <v-col md="11" class="py-0">
                    <v-divider></v-divider>
                  </v-col>
                  <template>
                    <v-col v-for="(formation, index) in vacancy.formation" cols="12" md="12" :key="`${index}index`">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="formation.course"
                            :items="getTrainingAreas(locale)"
                            item-text="text"
                            item-value="key"
                            :label="language.academicEdu"
                            dense
                            outlined
                            hide-details
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="5">
                          <v-row>
                            <v-col cols="12" md="6">
                              <!-- <pre>
                                {{getLevel(locale)}}
                              </pre> -->
                              <v-autocomplete
                                v-model="formation.level"
                                :items="getLevel(locale)"
                                item-text="text"
                                item-value="key"
                                :label="language.level"
                                dense
                                outlined
                                hide-details
                                required
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="formation.weight"
                                :label="language.weight"
                                min="1"
                                dense
                                outlined
                                hide-details
                                :rules="requiredRulesNumb"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="1" class="text-center mt-1">
                          <v-btn
                            x-small
                            outlined
                            fab
                            color="secondary"
                            @click="
                              index === 0
                                ? addFormation()
                                : removeFormation(index)
                            "
                          >
                            <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                            <v-icon v-else small>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>

                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="9">
                        <v-autocomplete
                          v-model="vacancy.areaInterest"
                          :items="getTrainingAreas(locale)"
                          item-text="text"
                          item-value="key"
                          :label="language.area"
                          dense
                          outlined
                          hide-details
                          required
                          chips
                          clearable
                          deletable-chips
                          multiple
                          small-chips
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="vacancy.weight"
                          :label="language.weight"
                          min="1"
                          dense
                          outlined
                          hide-details
                          :rules="requiredRulesNumb"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.number="
                            vacancy.professionalExperience[0].yearsExperience
                          "
                          :label="language.yearExperiencie"
                          type="number"
                          dense
                          outlined
                          hide-details
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          v-model="vacancy.professionalExperience[0].sector"
                          :items="sector"
                          item-text="text"
                          item-value="key"
                          :label="$t('select_sector', locale)"
                          dense
                          outlined
                          hide-details
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="vacancy.professionalExperience[0].weight"
                          :label="language.weight"
                          min="1"
                          dense
                          outlined
                          hide-details
                          :rules="requiredRulesNumb"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- {{ getCountiesMZ }} -->
                  <v-col cols="12" md="4">
                    <v-combobox
                      v-model="vacancy.place"
                      :items="getCountiesMZ"
                      :label="language.provinceState"
                      multiple
                      small-chips
                      outlined
                      dense
                      hide-details
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-tabs v-model="tabLang">
                      <v-tab><spa class="text-capitalize">{{$t('portuguese')}}</spa></v-tab>
                      <v-tab><spa class="text-capitalize">{{$t('english')}}</spa></v-tab>
                      
                      <v-tabs-items v-model="tabLang">
                        <v-tab-item>
                          <v-row>
                            <v-col cols="11" md="11" class="text-center">
                              <v-text-field
                                v-model="essentialSkill"
                                :label="`${language.essential_skills_and_competencies} - PT`"
                                prepend-inner-icon="event"
                                dense
                                outlined
                                hide-details
                                class="mt-2"
                              >
                                <template v-slot:prepend>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon small v-on="on" color="primary">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div v-html="$t(language.essential_skills_and_competencies_long)" style="width: 200px"></div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" md="1" class="text-center mt-2">
                              <v-btn
                                x-small
                                outlined
                                fab
                                color="secondary"
                                :disabled="essentialSkill ? false : true"
                                @click="addSkill('pt')"
                              >
                                <v-icon small>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-chip-group column multiple>
                            <v-chip
                              v-for="(skill, index) in vacancy.essentialSkills.pt"
                              label
                              small
                              close
                              :key="`${index}33`"
                              @click:close="removeSkill(index, 'pt')"
                              >{{ skill }}
                            </v-chip>
                          </v-chip-group>
                          <v-text-field
                            v-model="vacancy.jobTitle.pt"
                            :label="`${language.jobTitle} - PT`"
                            dense
                            outlined
                            hide-details
                            required
                            :rules="locale === 'pt' ? requiredRules : false"
                            class="mt-2 mb-5"
                          ></v-text-field>
                          <tiptap-vuetify
                            v-if="language.enterJobDesc"
                            v-model="vacancy.descriptionIntl.pt"
                            :extensions="extensions"
                            :placeholder="`${language.enterJobDesc} - PT`"
                            outlined
                            required
                            hide-details
                            class="mt-4"
                          />
                        </v-tab-item>
                        <v-tab-item>
                          <v-row>
                            <v-col cols="11" md="11" class="text-center">
                              <v-text-field
                                v-model="essentialSkill"
                                :label="`${language.essential_skills_and_competencies} - EN`"
                                prepend-inner-icon="event"
                                dense
                                outlined
                                hide-details
                                class="mt-2"
                              >
                                <template v-slot:prepend>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon small v-on="on" color="primary">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div v-html="$t(language.essential_skills_and_competencies_long)" style="width: 200px"></div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" md="1" class="text-center mt-2">
                              <v-btn
                                x-small
                                outlined
                                fab
                                color="secondary"
                                :disabled="essentialSkill ? false : true"
                                @click="addSkill('en')"
                              >
                                <v-icon small>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-chip-group column multiple>
                            <v-chip
                              v-for="(skill, index) in vacancy.essentialSkills.en"
                              label
                              small
                              close
                              :key="`${index}33`"
                              @click:close="removeSkill(index, 'en')"
                              >{{ skill }}
                            </v-chip>
                          </v-chip-group>
                          <v-text-field
                            v-model="vacancy.jobTitle.en"
                            :label="`${language.jobTitle} - EN`"
                            dense
                            outlined
                            hide-details
                            required
                            class="mt-2 mb-5"
                            :rules="locale === 'en' ? requiredRules : false"
                          ></v-text-field>
                          <tiptap-vuetify
                            v-if="language.enterJobDesc"
                            v-model="vacancy.descriptionIntl.en"
                            :extensions="extensions"
                            :placeholder="`${language.enterJobDesc} - EN`"
                            outlined
                            required
                            hide-details
                            class="mt-4"
                          />
                        </v-tab-item>
                      </v-tabs-items>
                    </v-tabs>
                  </v-col>

                  <template>
                    <v-col v-for="(doc, index) in vacancy.documents" cols="12" md="12" :key="`${index}langx1`">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="doc.name"
                            :items="language.documents"
                            item-text="text"
                            item-value="key"
                            :label="language.doc_label"
                            dense
                            outlined
                            hide-details
                            required
                            :rules="requiredRules"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="5">
                          <v-row>
                            <v-col cols="12" md="12">
                              <v-autocomplete
                                v-model="doc.rule"
                                :items="language.doc_rules"
                                item-text="text"
                                item-value="key"
                                :label="language.doc_rule_label"
                                dense
                                outlined
                                hide-details
                                required
                                :rules="requiredRules"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="1" class="text-center mt-1">
                          <v-btn
                            x-small
                            outlined
                            fab
                            color="secondary"
                            @click="
                              index === 0
                                ? addDocuments()
                                : removeDocuments(index)
                            "
                          >
                            <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                            <v-icon v-else small>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>

                  <v-col cols="12" md="6">
                    <v-menu
                      ref="startMenu"
                      v-model="startMenu"
                      :close-on-content-click="false"
                      :return-value.sync="vacancy.publicationDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="vacancy.publicationDate"
                          :label="language.startDate"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                          outlined
                          dense
                          hide-details
                          required
                          :rules="requiredRules"
                        >
                          <template v-slot:prepend>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-icon small v-on="on" color="primary">
                                  mdi-help-circle-outline
                                </v-icon>
                              </template>
                              <div v-html="$t('tooltip_date_vancancy')"></div>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="vacancy.publicationDate"
                        no-title
                        scrollable
                        :min="dateFormat()"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="startMenu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.startMenu.save(vacancy.publicationDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="endMenu"
                      v-model="endMenu"
                      :close-on-content-click="false"
                      :return-value.sync="vacancy.finalDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="vacancy.finalDate"
                          :label="language.endDate"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                          outlined
                          dense
                          hide-details
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="vacancy.finalDate"
                        no-title
                        scrollable
                        :min="dateFormat()"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="endMenu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.endMenu.save(vacancy.finalDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row
                      class="
                        d-flex
                        flex-row
                        justify-end
                        align-center
                        mt-2
                        px-3
                        py-6
                      "
                    >
                      <v-btn color="primary" @click="validate()"
                        >{{ language.proceed }} 1/2</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row class="pt-1">
                <v-col cols="12" md="6">
                  <v-select
                    v-model="vacancy.client"
                    :items="clients"
                    item-text="name"
                    item-value="id"
                    :label="language.clienteName"
                    dense
                    outlined
                    hide-details
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="vacancy.type"
                    :items="language.tvacancy"
                    item-text="name"
                    item-value="id"
                    :label="language.typeVacancy"
                    dense
                    outlined
                    hide-details
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="vacancy.category"
                    :items="language.categoryOptions"
                    item-text="name"
                    item-value="_id"
                    :label="language.category"
                    dense
                    outlined
                    hide-details
                    readonly
                  ></v-select>
                </v-col>
                <template>
                  <v-col v-for="(lang, index) in vacancy.languages" cols="12" md="12" :key="index">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="lang.name"
                          :items="language.languageOptions"
                          item-text="label"
                          item-value="val"
                          :label="language.language"
                          dense
                          outlined
                          hide-details
                          required
                          readonly
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              v-model="lang.level"
                              :items="language.levelLanguageOptions1"
                              item-text="text"
                              item-value="key"
                              :label="language.level"
                              dense
                              outlined
                              hide-details
                              required
                              readonly
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.number="lang.weight"
                              :label="language.weight"
                              type="number"
                              dense
                              outlined
                              hide-details
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <v-col cols="12" md="12" class="py-0">
                  <v-divider></v-divider>
                </v-col>
                <template>
                  <v-col v-for="(formation, index) in vacancy.formation" cols="12" md="12" :key="`${index}index`">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="formation.course"
                          :items="getTrainingAreas(locale)"
                          item-text="text"
                          item-value="key"
                          :label="language.academicEdu"
                          dense
                          outlined
                          hide-details
                          readonly
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              v-model="formation.level"
                              :items="getLevel(locale)"
                              item-text="text"
                              item-value="key"
                              :label="language.level"
                              dense
                              outlined
                              hide-details
                              required
                              readonly
                            ></v-autocomplete>
                            <!-- <v-text-field
                              v-model="formation.level"
                              :label="language.level"
                              dense
                              outlined
                              hide-details
                              required
                              readonly
                            ></v-text-field> -->
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model.number="formation.weight"
                              :label="language.weight"
                              type="number"
                              dense
                              outlined
                              hide-details
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="9">
                      <v-autocomplete
                        v-model="vacancy.areaInterest"
                        :items="getTrainingAreas(locale)"
                        item-text="text"
                        item-value="key"
                        :label="language.area"
                        dense
                        outlined
                        hide-details
                        required
                        chips
                        clearable
                        deletable-chips
                        multiple
                        small-chips
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model.number="vacancy.weight"
                        :label="language.weight"
                        type="number"
                        min="1"
                        dense
                        outlined
                        hide-details
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="8">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          vacancy.professionalExperience[0].yearsExperience
                        "
                        :label="language.yearExperiencie"
                        type="number"
                        dense
                        outlined
                        hide-details
                        required
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        v-model="vacancy.professionalExperience[0].sector"
                        :items="sector"
                        item-text="text"
                        item-value="key"
                        :label="$t('select_sector', locale)"
                        dense
                        outlined
                        hide-details
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model.number="
                          vacancy.professionalExperience[0].weight
                        "
                        :label="language.weight"
                        type="number"
                        dense
                        outlined
                        hide-details
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="vacancy.place"
                    :items="getCountiesMZ"
                    :label="language.provinceState"
                    multiple
                    small-chips
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="12">
                  <v-tabs v-model="tabLang">
                    <v-tab><spa class="text-capitalize">{{$t('portuguese')}}</spa></v-tab>
                    <v-tab><spa class="text-capitalize">{{$t('english')}}</spa></v-tab>
                    
                    <v-tabs-items v-model="tabLang">
                      <v-tab-item>
                        <v-text-field
                          v-model="vacancy.jobTitle.pt"
                          :label="`${language.jobTitle} - PT`"
                          dense
                          outlined
                          hide-details
                          required
                          readonly
                          class="mt-2 mb-5"
                        ></v-text-field>
                        <tiptap-vuetify
                          v-if="language.enterJobDesc"
                          v-model="vacancy.descriptionIntl.pt"
                          :extensions="extensions"
                          :placeholder="`${language.enterJobDesc} - PT`"
                          outlined
                          required
                          hide-details
                        />
                      </v-tab-item>
                      <v-tab-item>
                        <v-text-field
                          v-model="vacancy.jobTitle.en"
                          :label="`${language.jobTitle} - EN`"
                          dense
                          outlined
                          hide-details
                          required
                          class="mt-2 mb-5"
                          readonly
                        ></v-text-field>
                        <tiptap-vuetify
                          v-if="language.enterJobDesc"
                          v-model="vacancy.descriptionIntl.en"
                          :extensions="extensions"
                          :placeholder="`${language.enterJobDesc} - EN`"
                          outlined
                          required
                          hide-details
                        />
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </v-col>
                <template>
                  <v-col v-for="(doc, index) in vacancy.documents" cols="12" md="12" :key="`${index}lang-x2`">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="doc.name"
                          :items="language.documents"
                          item-text="text"
                          item-value="key"
                          :label="language.doc_label"
                          dense
                          outlined
                          hide-details
                          required
                          readonly
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-autocomplete
                              v-model="doc.rule"
                              :items="language.doc_rules"
                              item-text="text"
                              item-value="key"
                              :label="language.doc_rule_label"
                              dense
                              outlined
                              hide-details
                              required
                              readonly
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="1" class="text-center mt-1">
                        <v-btn
                          x-small
                          outlined
                          fab
                          disabled
                          color="secondary"
                          @click="
                            index === 0
                              ? addDocuments()
                              : removeDocuments(index)
                          "
                        >
                          <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                          <v-icon v-else small>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="vacancy.publicationDate"
                    :label="language.startDate"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    required
                  >
                    <template v-slot:prepend>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on" color="primary">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <div v-html="$t('tooltip_date_vancancy')"></div>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="vacancy.finalDate"
                    :label="language.endDate"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-row
                    class="
                      d-flex
                      flex-row
                      justify-end
                      align-center
                      mt-2
                      px-3
                      py-6
                    "
                  >
                    <template>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on" class="mx-1">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <div v-html="$t('tooltip_save&close_vancancy')"></div>
                      </v-tooltip>
                    </template>

                    <v-btn
                      v-if="!vacancy.status"
                      text
                      color="primary"
                      @click="publish(false)"
                      >{{ language.closeSave }}</v-btn
                    >
                    <v-btn color="primary" dense @click="publish(true)">{{
                      language.publish
                    }}</v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <select-language-modal
      :dialog="lanDialog"
      @close="closeLangDialog()"
      @set="selectedLanguage"
    />

    <stage-dialog
      :dialog="dialogStage"
      :language="language"
      :vacancyStages="setStages"
      :locale="locale"
      @continue="continueToPub"
      @close="closeStageDialog()"
    ></stage-dialog>

    <stage-edit-dialog
      :dialog="dialogStageEdit"
      :language="language"
      :locale="locale"
      :vacancyStages="setStages"
    >
    </stage-edit-dialog>

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <alert-dialog
      :dialog="dialogAlert"
      :message="$t('CompleteProfile')"
      @close="closeAlert()"
    />
    <progress-dialog :processing="isLoading" />
    <share-dialog @finish="closeMediaDialog()" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="closeSuccessDialog()"
    />

    <v-snackbar v-model="snackbar" elevation="24">
      {{ $t("worning_match", locale) }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          {{ $t("close", locale) }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbar_0" elevation="24">
      {{ ruleText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar_0 = false">
          {{ $t("close", locale) }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { GET_CLIENTS_ENTITY_QUERY } from "./../../client/graphql/Query";
import { GET_VACANCY_BY_ID_QUERY } from "../graphql/Query.resolver";
import {
  CREATE_VACANCY_MUTATION,
  UPDATE_VACANCY_MUTATION,
} from "../graphql/Mutation.resolver";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import moment from "moment";
import SelectLanguageModal from "../components/SelectLanguageModal.vue";
import StageDialog from "../components/StageDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import AlertDialog from "./../../../components/AlertDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import ShareDialog from "./../components/ShareDialog.vue";
import StageEditDialog from "./../components/StageEditDialog.vue";
import Vancancy from "@/models/Vacancy";
import { ADD_DIALOG } from "@/mixins/dialog";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "Publish",
  mixins: [ADD_DIALOG],
  components: {
    SelectLanguageModal,
    StageDialog,
    TiptapVuetify,
    ErrorDialog,
    AlertDialog,
    ProgressDialog,
    ShareDialog,
    SuccessDialog,
    StageEditDialog,
  },
  data: () => ({
    alert: true,
    dialogAlert: false,
    dialogStage: false,
    dialogStageEdit: false,
    clients: [],
    ruleText: "",
    setStages: [
      {
        menu: false,
        menu1: false,
        name: "Anuncio de vaga",
        analyze: "Anuncio de vaga",
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        participants: [],
      },
    ],
    areaInterest: "",
    lanDialog: false,
    e1: 1,
    endMenu: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    fields: {
      name: "",
      areasExpertise: [],
      email: "",
      nuit: "",
      license: "",
      telephone: "",
      address: "",
    },
    language: {},
    locale: "",
    tabLang: "",
    on: false,
    shareDialog: false,
    startMenu: false,
    shareVacancy: {},
    vacancy: new Vancancy(),
    valid: true,
    skipQuery: true,
    snackbar: false,
    snackbar_0: false,
    essentialSkill: "",
  }),
  apollo: {
    clients: {
      query: GET_CLIENTS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.$route.query.id,
        };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.vacancy = data.vacancy;
          this.vacancy.professionalExperience = data.vacancy
            .professionalExperience
            ? data.vacancy.professionalExperience
            : [{ yearsExperience: 0, weight: 25 }];
          this.vacancy.client = this.vacancy.client
            ? this.vacancy.client.id
            : undefined;
          this.vacancy.formation = this.vacancy.formation.map((f) => ({
            course: f.course,
            level: f.level,
            weight: f.weight,
          }));

          if (!data.vacancy.jobTitle[data.vacancy.locale]) {
            this.vacancy.jobTitle[data.vacancy.locale] = data.vacancy.title;
          }

          if (!data.vacancy.descriptionIntl[data.vacancy.locale]) {
            this.vacancy.descriptionIntl[data.vacancy.locale] = data.vacancy.description;
          }

          //  this.vacancy.areaInterest =this.vacancy.areaInterest? this.vacancy.areaInterest:[];

          delete this.vacancy.candidacies;
          delete this.vacancy.entity;
          this.locale = this.vacancy.locale;
          this.language = this.getPublishingLang(this.locale);
          this.setStages = this.vacancy.stages;
          // this.dialogStage = true;
        }
      },
    },
  },
  async created() {
    if (!this.verifyProfile()) {
      this.dialogAlert = true;
      this.vacancy.professionalExperience = [
        { yearsExperience: 0, weight: 25 },
      ];
    } else {
      this.vacancy.formation = [
        {
          course: "",
          level: "",
          weight: 25,
        },
      ];
      this.vacancy.languages = [
        {
          name: "",
          level: "",
          weight: 25,
        },
      ];
      this.vacancy.documents = [
        {
          name: "",
          rule: "",
        },
      ];
      this.vacancy.descriptionIntl = { pt: '', en: '' };
      this.vacancy.jobTitle = { pt: '', en: '' };
      this.vacancy.essentialSkills = { pt: [], en: [] };
      this.vacancy.areaInterest = [];
      this.vacancy.professionalExperience = [
        { yearsExperience: 0, weight: 25 },
      ];
      this.vacancy.weight = this.vacancy.weight ? this.vacancy.weight : 25;
      if (this.$route.query.id) {
        this.$apollo.queries.vacancy.skip = false;
        await this.$apollo.queries.vacancy.refetch();
      } else {
        this.lanDialog = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getCountiesMZ: "library/countiesMZ",
      getPublishingLang: "library/publishingLang",
      getTrainingAreas: "library/trainingAreas",
      getLevel: "library/getLevelAcademic",
    }),
    requiredRules() {
      return [(v) => !!v || this.$t("required_field", this.locale)];
    },
    sector() {
      return [
        { key: "private_sec", text: this.$t("private_sec", this.locale) },
        { key: "NGO", text: this.$t("NGO", this.locale) },
        { key: "public_sec", text: this.$t("public_sec", this.locale) },
      ];
    },
    requiredRulesNumb() {
      return [
        (v) => {
          if (v === "0") {
            this.ruleText = this.$t("worning_score", this.locale);
            this.snackbar_0 = true;
            return this.$t("worning_score", this.locale);
          }
          if (!Number.isInteger(Number(v))) {
            this.ruleText = this.$t("Insert_integers", this.locale);
            this.snackbar_0 = true;
            return this.$t("Insert_integers", this.locale);
          }
          // Adicione mais regras conforme necessário
        },
      ];
    },
  },
  // watch: {
  //   tabLang(newVal) {
  //     console.log({newVal});
  //     if (newVal === 'portuguese')
  //       this.locale = 'pt';
  //     else 
  //       this.locale = 'en';
  //   }
  // },
  methods: {
    ...mapActions({
      setVacancyState: "vacancy/setVacancy",
      updateVacancyState: "vacancy/updateVacancy",
    }),
    validateIntNumber(value) {
      console.log(value);
      value = value.replace(/[^0-9]/g, "");
    },
    closeStageDialog() {
      this.dialogStage = false;
      this.vacancy.stages = [];
    },
    continueToPub(stages) {
      this.dialogStage = false;
      this.vacancy.stages = stages;
    },
    closeLangDialog() {
      this.lanDialog = false;
      this.dialogStage = true;
    },
    addFormation() {
      this.vacancy.formation.push({ course: "", level: "" });
    },
    addLanguage() {
      this.vacancy.languages.push({ name: "", level: "" });
    },
    addDocuments() {
      this.vacancy.documents.push({ name: "", rule: "" });
    },

    getTotalWeight() {
      let total = 0;
      this.vacancy.languages = this.vacancy.languages.map((element) => {
        total += parseInt(element.weight);
        element.weight = parseInt(element.weight);
        return element;
      });
      this.vacancy.formation = this.vacancy.formation.map((element) => {
        total += parseInt(element.weight);
        element.weight = parseInt(element.weight);
        return element;
      });
      this.vacancy.professionalExperience = this.vacancy.professionalExperience.map(
        (element) => {
          total += parseInt(element.weight);
          element.weight = parseInt(element.weight);
          return element;
        }
      );
      this.vacancy.weight = parseInt(this.vacancy.weight);
      total += parseInt(this.vacancy.weight);
      return total;
    },
    closeAlert() {
      this.dialogAlert = false;
      this.$router.push("/dashboard/entity/profile");
    },
    closeSuccessDialog() {
      this.$router.push("/dashboard/entity/vacancies");
    },
    dateFormat() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    async publish(status) {
      // let checkDoc = this.vacancy.documents.find((dc) => dc.name === "");
      // console.log("=>>>>>", checkDoc);
      // if (checkDoc) {
      //   delete this.vacancy.documents;
      // }
      if (this.getTotalWeight() <= 100) {
        let tests = [];
        this.isLoading = true;
        this.vacancy.status = status;
        this.vacancy.available = "public";
        this.vacancy.locale = this.locale;

        try {
          if (this.vacancy.id) {
            delete this.vacancy.stages;
            const { data } = await this.$apollo.mutate({
              mutation: UPDATE_VACANCY_MUTATION,
              variables: {
                id: this.vacancy.id,
                vacancyInput: this.vacancy,
              },
            });
            this.updateVacancyState(data.updateVacancy);
            this.success = this.$t("Vacancyupdsuccess");
            this.dialogStage = false;
            this.showSuccess = true;
          } else {
            if (this.vacancy.stages.length) {
              this.vacancy.stages = this.vacancy.stages.map((stage) => {
                delete stage.menu;
                delete stage.menu1;
                if (stage.tests === undefined) {
                  delete stage.tests;
                } else {
                  tests = stage.tests = stage.tests.map((t) => {
                    if (typeof t.id !== "undefined") {
                      return t.id;
                    }
                    if (t) {
                      return t;
                    }
                    return [];
                  });
                }
                return {
                  ...stage,
                  tests,
                  participants: stage.participants.map((p) => {
                    return p.id;
                  }),
                };
              });
            } else {
              this.vacancy.stages = [
                {
                  name: this.$t("default_stage_name", this.locale),
                  analyze: this.$t("default_stage_name", this.locale),
                  tests: [],
                  participants: [],
                },
              ];
            }

            const { data } = await this.$apollo.mutate({
              mutation: CREATE_VACANCY_MUTATION,
              variables: {
                vacancyInput: { 
                  ...this.vacancy, 
                  title: this.vacancy.jobTitle[this.locale], 
                  description: this.vacancy.descriptionIntl[this.locale] 
                },
              },
            });
            this.setVacancyState(data.createVacancy);
            if (status) {
              this.openShareDialog(data.createVacancy);
            } else {
              this.success = this.$t("Vacancycrtuccess");

              this.showSuccess = true;
            }
          }
        } catch (error) {
          console.log(error);
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.snackbar = true;
      }
    },
    removeFormation(index) {
      this.vacancy.formation.splice(index, 1);
    },
    removeLanguage(index) {
      this.vacancy.languages.splice(index, 1);
    },
    removeDocuments(index) {
      this.vacancy.documents.splice(index, 1);
    },
    selectedLanguage(locale) {
      this.language = this.getPublishingLang(locale);
      this.locale = locale;
      this.tabLang = locale === 'pt' ? 0 : 1;
      this.lanDialog = false;
      this.dialogStage = true;
    },
    openShareDialog(vacancy) {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareVacancy", vacancy);
    },
    closeMediaDialog() {
      this.$router.push("/dashboard/entity/vacancies");
    },
    validate() {
      if (this.getTotalWeight() <= 100) {
        if (this.$refs.form.validate()) {
          this.e1 = 2;
        }
      } else {
        this.snackbar = true;
      }
    },
    verifyProfile() {
      let countFieldCand = 0,
        countField = 0;
      for (const field in this.fields) {
        countField += 1;
        var type = typeof this.currentUser.entity[field];
        if (type === "string") {
          if (this.currentUser.entity[field] !== "") {
            countFieldCand += 1;
          }
        } else if (
          this.currentUser.entity[field] &&
          this.currentUser.entity[field].length > 0
        ) {
          countFieldCand += 1;
        }
      }
      return countFieldCand === countField;
    },
    addSkill(lang) {
      this.vacancy.essentialSkills[lang].push(this.essentialSkill);
      this.essentialSkill = "";
    },
    removeSkill(index, lang) {
      this.vacancy.essentialSkills[lang].splice(index, 1);
    },
  },
};
</script>
