<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="status === 'finish' ? '400' : '800'"
      :fullscreen="isMobile"
    >
      <v-card>
        <v-card-title class="justify-center">
          <span class="headline">{{ vacancy.entity.name }}</span>
        </v-card-title>
        <v-card-text>
          <span
            class="text-justify black--text text-subtitle-1"
            v-html="$t('skills_competencies_listed_below')"
          ></span>
          <v-list>
            <v-list-item v-for="item in vacancy.essentialSkills[locale]" :key="item">
              <v-list-item-action>
                <v-checkbox v-model="candidateSkills" :value="item"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{item}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-6 py-8">
            <!-- <v-spacer></v-spacer> -->

            <v-btn color="primary" @click="close" dense text>{{
              $t("cancel")
            }}</v-btn>

            <v-btn
              color="primary"
              sense
              @click="save"
              >{{ $t("btn_submit") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    vacancy: Object,
    locale: String,
  },
  data () {
    return {
      candidateSkills: [],
      sound: true,
      widgets: false,
      isMobile: false,
    }
  },
  created() {
    if (/Mobi|Android/i.test(window.navigator.userAgent)) {
      this.isMobile = true;
      console.log("É um dispositivo móvel");
    } else {
      this.isMobile = false;
      console.log("Não é um dispositivo móvel");
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save', this.candidateSkills);
    },
  },
}
</script>