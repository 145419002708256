import gql from "graphql-tag";

export default gql`
  {
    id
    available
    candidacies {
      documents
      specialDate
      candidate {
        id
      }
    }
    description
    descriptionIntl {
      pt
      en
    }
    entity {
      id
      about
      logo
      name
      media {
        facebook
        linkedin
        twitter
      }
    }
    finalDate
    formation {
      course
      level
    }
    languages {
      name
      level
    }
    documents {
      name
      rule
    }
    essentialSkills {
      pt
      en
    }
    locale
    category
    place
    private
    professionalExperience {
      description
      yearsExperience
    }
    publicationDate
    status
    title
    jobTitle {
      pt
      en
    }
    type
    stages {
      id
      name
      tests {
        description
        duraction
        image
        openResponse
        title
        type
        questions {
          question
          objective
          implication
          file
          fileResponse
          openQuestion
          alternatives {
            answer
            value
            textResponse
          }
          totalCorrect
          percentage
          picture
        }
      }
    }
  }
`;
