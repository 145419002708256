import gql from "graphql-tag";
import candidateFragment from "./../../candidate/graphql/CandidateFragment";
import clientFragment from "./../../client/graphql/ClientFrangment";
import TestFragment from "./TestFragment";

export default gql`{
  id
  available
  candidacies {
    id
    appliedFor
    candidate {
      ...${candidateFragment}
    }
    skills
    documents
    stages {
      name
      analyze
      rating
      status
      startDate
      startTime
      endDate
      endTime
      participants{
        id
        name
        photo
      }
    }
    notes {
      name
      text
      time
    }
    tests {
      id
      candidate
      date
      description
      image
      questions {
        question
        objective
        implication
        file
        fileResponse
        openQuestion
        alternatives {
          answer
          response
          value
          textResponse
        }
        observations{
          id:_id
          name
          time
          result
          textObservation
        }
        answer
        totalCorrect
        percentage
        picture
      }
      result
      stage
      status
      time
      title
      type
      # user {
      #   id
      #   name
      #   office
      #   photo
      # }
      vacancy {
        id
        title
      }
    }
    favorite
    status
  }
  client {
    ...${clientFragment}
  }
  description 
  descriptionIntl {
    pt
    en
  }
  entity {
    id
    logo
    name
    about
    media{
      facebook
      linkedin
      twitter
    }
  }
  finalDate
  formation {
    course
    level
    status
    institution
    year
    weight
  }
  languages {
    name
    level
    weight
  }
  documents {
    name
    rule
  }
  areaInterest
  weight
  locale
  category
  place
  private
  professionalExperience {
    description
    yearsExperience
    sector
    weight
  }
  publicationDate
  status
  title
  jobTitle {
    pt
    en
  }
  type
  views
  stages {
    name
    analyze
    startDate
    startTime
    endDate
    endTime
    tests{
      ...${TestFragment}
    }
    participants{
      id
      name
      photo
    }
  }
  essentialSkills {
    pt
    en
  }
}`;
